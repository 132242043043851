import { get } from 'lodash';
import {
  ROUTE_404,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_COMMENT_DEEP_LINK,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  ROUTE_SEARCH,
  ROUTE_DEV_PLAYGROUND,
  DISCUSSION,
  QUESTION,
  isMembersOnly,
  LAYOUT_TYPE_PINBOARD,
  createPermissionCheckerClientAPI,
  EXPERIMENT_RECENT_ACTIVITY_CATS,
  EXPERIMENT_SINGLE_POST_PAGE_FETCH,
} from '@wix/communities-forum-client-commons';
import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import { MODAL_TYPE_EDIT_POST } from '../components/modals/post-create-modal/post-edit-modal-type';
import { Router } from '../../common/router';
import { fetchCategory } from '../actions/fetch-category';
import { fetchSubcategories } from '../../common/actions/fetch-categories';
import { fetchCategoryPosts } from '../actions/fetch-category-posts';
import { fetchUpdatedCategoryPosts } from '../actions/fetch-updated-category-posts';
import { resetCategoryFilter, resetPostTypeFilter } from '../actions/reset-filter';
import { getCurrentUser, isBlocked } from '../../common/store/current-user/current-user-selectors';
import { getIsMobile, isSite, isSSR } from '../../common/store/basic-params/basic-params-selectors';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';
import { getPostEntityIdsForPage } from '../selectors/pagination-selectors';
import { openModal } from '../../common/modals/framework/store/modal-actions';
import search, { clearSearchResults, MIN_QUERY_LENGTH } from '../actions/search';
import { setIsLoading } from '../actions/set-is-loading';
import { decodeSpaces } from '../services/query-encoding';
import { getQueryParam } from '../services/query-params';
import { isComingToSamePageFromPost } from '../services/is-coming-to-same-page-from-post';
import { fetchPost } from '../actions/fetch-post';
import { getPostBySlug } from '../selectors/post-selectors';
import { getCategoryFilter, getPostTypeFilter } from '../selectors/filter-selectors';
import { stopLoadingCategory } from '../actions/stop-loading-category';
import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { fetchPostPageData } from '../actions/fetch-post-page-data';
import { getCategoryBySlug, getCategory } from '../../common/selectors/categories-selectors';
import { addErrorState } from '../../common/store/debug-state/debug-state-actions';
import { getPreviousMatches } from '../../common/router/router-selectors';
import { fetchFooterPosts } from '../actions/fetch-footer-posts';
import { fetchRelatedPosts } from '../actions/fetch-related-posts';
import { getStyleParams } from '../../common/store/style-params/style-params-selectors';
import { FILTER_SHOW_ALL } from '../constants/filtering';
import { emitOpenPost } from '../actions/post-socket';
import { incrementPostViewCount } from '../actions/increment-post-view-count';
import { pageOpened } from '../actions/page-opened';
import { getIsDemoMode } from '../../common/store/instance-values/instance-values-selectors';
import { POST_PAGE, FEED_PAGE, CATEGORIES_PAGE, POST_EDIT_PAGE } from '../constants/page-names';
import { emitOpenCategory } from '../actions/category-socket';
import { getIsSearchLoading } from '../selectors/is-loading-selectors';
import { getForumSectionUrl } from '../../common/services/get-section-url';
import { getUrl } from '../../common/store/location/location-selectors';
import { getLayoutType } from '../selectors/layout-selectors';
import { getGuestPermissions } from '@wix/communities-forum-universal/dist/src/services/permissions/permissions';
import { UrlMappingsKeys } from '@wix/url-mapper-utils';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { setMetaTagRobotsNoIndex } from '../services/set-metatag-robots-noindex';
import { decorateCategoryWithSeoAttributes } from '@wix/forum-seo-entities-decorator';
import { getContextToken } from '../services/context-token';
import { getPaginationConfig } from '../constants/comments-pagination-config';
import classicPagintationUtils from '../services/init-classic-pagination';
import { FeedType, getFeedType } from '../containers/header-navigation';
import { markPostAsRead, markPostViewed } from '../containers/user-activity';
import { resolveWixCommentsDeepLink } from '../actions/resolve-wix-comments-deep-link';
import { getIsPostNew } from '../containers/user-activity/user-activity.selectors';
import { NavigationType } from '../constants/navigation-type';
import { FETCH_POST_DATA_V1, FETCH_POST_DATA_V2 } from '../constants/interactions';

function logErrorState(store, handler) {
  return (error) => {
    store.dispatch(addErrorState(error));
    return handler(error);
  };
}

async function handleFallbackRedirect(pathname, redirect, wixCodeApi) {
  const path = await matchCustomPath(wixCodeApi, pathname);
  return redirect(path ? path : ROUTE_404);
}

const assertUserLoggedInAndNotBlocked = (state, redirect) => {
  const currentUser = getCurrentUser(state);
  if (currentUser) {
    if (isBlocked(state)) {
      return { isUserValid: false, redirectState: redirect(ROUTE_ACCOUNT_SUSPENDED) };
    }
  } else {
    return { isUserValid: false, redirectState: redirect(ROUTE_LOGIN) };
  }
  return { isUserValid: true };
};

const createCreatePostRouteHandler = (store, postType) => (_, redirect) => {
  const state = store.getState();
  const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
  if (!isUserValid) {
    return redirectState;
  }
  if (getIsMobile(state)) {
    store.dispatch(openModal(MODAL_TYPE_CREATE_POST, { postType }));
  }
  if (!isSSR(state)) {
    store.dispatch(pageOpened({ page: POST_EDIT_PAGE }));
  }
};

const createPostEditRouteHandler =
  (store) =>
  ({ params }, redirect) => {
    const state = store.getState();
    const { isUserValid, redirectState } = assertUserLoggedInAndNotBlocked(state, redirect);
    if (!isUserValid) {
      return redirectState;
    }
    if (!isSSR(state)) {
      store.dispatch(pageOpened({ page: POST_EDIT_PAGE }));
    }

    if (getIsMobile(state)) {
      store.dispatch(openModal(MODAL_TYPE_EDIT_POST));
    }
    const post = getPostBySlug(state, params.postSlug);
    if (!post) {
      return Promise.all([
        store.dispatch(fetchCategory(params.categorySlug)),
        store.dispatch(fetchPost(params.postSlug)),
      ]);
    }
  };

const createCategoryPageHandler =
  (store, wixCodeApi, compId, config) =>
  ({ params: { categorySlug, page: _page }, pathname }, redirect) => {
    let state = store.getState();
    const style = getStyle(config, state);
    const isMobile = getIsMobile(state);
    const feedType = getFeedType(state);
    const isLayoutTypePinboard = getLayoutType(state, style) === LAYOUT_TYPE_PINBOARD;
    const siteMemberId =
      feedType === FeedType.MyPosts ? getCurrentUser(state)?.siteMemberId : undefined;

    const page = !isMobile && isLayoutTypePinboard ? 1 : parseInt(_page, 10) || 1; // no pagination per url on masonry
    if (!categorySlug) {
      // all posts pseudo category

      const prevFeedType = get(getPreviousMatches(state)[1], 'queryParams.feedType');
      if (feedType !== prevFeedType) {
        store.dispatch(resetCategoryFilter());
      }

      // We need to update state after dispatch;
      state = store.getState();
      const category = getCategoryBySlug(state, getCategoryFilter(state)) || { _id: null };

      if (!isLayoutTypePinboard) {
        const isComingBackToSamePageFromPost = isComingToSamePageFromPost(state, {
          type: NavigationType.Feed,
          payload: { name: feedType, page },
        });
        const isPagePostsLoaded = !!getPostEntityIdsForPage(state, page, undefined, siteMemberId)
          .length;
        if (isComingBackToSamePageFromPost && isPagePostsLoaded) {
          store.dispatch(stopLoadingCategory(null));
          return store.dispatch(
            fetchUpdatedCategoryPosts({ categoryId: category._id, siteMemberId, page }),
          );
        }
      }

      return store
        .dispatch(
          fetchCategoryPosts({
            categoryId: category._id,
            siteMemberId,
            page,
          }),
        )
        .catch(logErrorState(store, () => handleFallbackRedirect(pathname, redirect, wixCodeApi)));
    }

    const category = getCategoryBySlug(state, categorySlug);
    if (isSite(state)) {
      if (category) {
        const sectionUrl = getForumSectionUrl(wixCodeApi);
        const seoModel = decorateCategoryWithSeoAttributes({ category, sectionUrl });

        wixCodeApi.seo.renderSEOTags(seoModel);
      }
    }

    let promise;
    let isComingBackToSameCategoryFromPost;

    if (category) {
      isComingBackToSameCategoryFromPost = isComingToSamePageFromPost(state, {
        type: NavigationType.Category,
        payload: { name: category.slug, page },
      });
      promise = Promise.resolve(category);
      if (!isComingBackToSameCategoryFromPost || isLayoutTypePinboard) {
        store.dispatch(fetchCategory(categorySlug));
      }
    } else {
      isComingBackToSameCategoryFromPost = false;
      promise = store.dispatch(fetchCategory(categorySlug));
    }

    return promise
      .then((category) => {
        const promises = [];
        if (category._id) {
          promises.push(store.dispatch(fetchSubcategories(category._id)));
        }
        const state = store.getState();
        const user = getCurrentUser(state);

        const { can } = createPermissionCheckerClientAPI({
          user,
          usersPermissions: user ? user.userPermissions : getGuestPermissions(),
          getCategory: (categoryId) => getCategory(state, categoryId),
        });

        if (can('read', 'category', category)) {
          const prevCategorySlug = get(getPreviousMatches(state)[1], 'params.categorySlug', null);
          const hasActiveFilter = getPostTypeFilter(state) !== FILTER_SHOW_ALL;
          if (prevCategorySlug !== category.slug && hasActiveFilter) {
            promises.push(store.dispatch(resetPostTypeFilter()));
          }
          const isPagePostsLoaded = !!getPostEntityIdsForPage(state, page, category._id).length;

          if (!isComingBackToSameCategoryFromPost || !isPagePostsLoaded || isLayoutTypePinboard) {
            promises.push(
              store
                .dispatch(
                  fetchCategoryPosts({
                    categoryId: category._id,
                    page,
                  }),
                )
                .then((posts) => {
                  if (!isSSR(state)) {
                    const isMainPageEnabled = getIsMainPageEnabled(state, style);
                    store.dispatch(
                      pageOpened({
                        page: FEED_PAGE,
                        category,
                        isMainPageEnabled,
                      }),
                    );
                    store.dispatch(emitOpenCategory(category._id));
                  }
                  return posts;
                }),
            );
          } else if (
            isComingBackToSameCategoryFromPost &&
            isPagePostsLoaded &&
            !isLayoutTypePinboard
          ) {
            promises.push(
              store.dispatch(fetchUpdatedCategoryPosts({ categoryId: category._id, page })),
            );
          }
        }

        return Promise.all(promises);
      })
      .catch(logErrorState(store, () => handleFallbackRedirect(pathname, redirect, wixCodeApi)));
  };

const createSearchPageRouter =
  (store, wixCodeApi) =>
  ({ params: { query } }) => {
    setMetaTagRobotsNoIndex(wixCodeApi);

    if (getIsSearchLoading(store.getState())) {
      // on mobile the search input itself triggers data fetch for perf optimization
      return;
    }
    store.dispatch(clearSearchResults());
    if (query && query.length >= MIN_QUERY_LENGTH) {
      store.dispatch(setIsLoading('search', '', true));
      return store.dispatch(search({ query: decodeSpaces(query) }));
    }
  };

const createPostPageRouter =
  (store, wixCodeApi, wixCommentsApi, handlePostChange, fedopsLogger) =>
  ({ params: { categorySlug, postSlug, page, deepLinkData }, pathname }, redirect) => {
    const state = store.getState();
    const isServerSideRendering = isSSR(state);
    const isSinglePostPageFetchEnabled = isExperimentEnabled(
      state,
      EXPERIMENT_SINGLE_POST_PAGE_FETCH,
    );
    const interactionName = isSinglePostPageFetchEnabled ? FETCH_POST_DATA_V2 : FETCH_POST_DATA_V1;
    const category = getCategoryBySlug(state, categorySlug);
    if (!category) {
      const categoryById = getCategory(state, categorySlug);
      if (categoryById && categoryById.slug && postSlug) {
        return redirect(`/${categoryById.slug}/${postSlug}`);
      }
    }
    const prevMatches = getPreviousMatches(state);
    const isMobile = getIsMobile(state);
    const isRecentActivityCatsEnabled = isExperimentEnabled(state, EXPERIMENT_RECENT_ACTIVITY_CATS);

    const fetchPostData = async () => {
      // Check for existing post in state to prevent refetch, when moving throught comments
      const post = getPostBySlug(state, postSlug);
      let response;

      if (!post) {
        response = await store.dispatch(fetchPostPageData(postSlug, parseInt(page, 10) || 1));
      } else {
        // To mimic response object model, which is expected
        response = { post: getPostBySlug(state, postSlug) };
      }

      // To provide latest routeParams to wixCommentsApi.watch.pagination.onChange
      handlePostChange({ params: { categorySlug, postSlug, page, deepLinkData }, pathname });

      const ctxToken = getContextToken(response.post._id);
      const commentsPaginationConfig = classicPagintationUtils.resolveCommentsPaginationConfig({
        initialPaginationConfig: getPaginationConfig(response.post),
        getPageParam: () => page ?? '',
      });

      const withVotes = response.post.commentingType === 'vote';

      if (deepLinkData) {
        await wixCommentsApi.fetchDeepLink(
          deepLinkData,
          response.post._id,
          ctxToken,
          commentsPaginationConfig,
          withVotes,
        );
      } else {
        await wixCommentsApi.fetchComments(
          response.post._id,
          ctxToken,
          commentsPaginationConfig,
          withVotes,
        );
      }

      return response;
    };

    if (
      !isMobile &&
      prevMatches[1] && // here 0-th match is the current match
      prevMatches[1].pathname.includes(`${categorySlug}/${postSlug}`)
    ) {
      return fetchPostData();
    }
    fedopsLogger.interactionStarted(interactionName);
    const categoryPromise = category
      ? Promise.resolve(category)
      : store.dispatch(fetchCategory(categorySlug));

    const postPromise = fetchPostData();

    const relatedPostsPromise = isSinglePostPageFetchEnabled
      ? Promise.resolve()
      : store.dispatch(fetchRelatedPosts(postSlug));
    const footerPostsPromise = isSinglePostPageFetchEnabled
      ? Promise.resolve()
      : store.dispatch(fetchFooterPosts({ categoryIdOrSlug: categorySlug, excludeSlug: postSlug }));

    return Promise.all([categoryPromise, postPromise, relatedPostsPromise, footerPostsPromise])
      .then((results) => {
        const category = results[0];
        const post = results[1].post;
        const isCategorySlugDiffers =
          categorySlug !== category._id && categorySlug !== category.slug;
        const isPostSlugDiffers = postSlug !== post._id && postSlug !== post.slug;
        if (isCategorySlugDiffers || isPostSlugDiffers) {
          return store.dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
        }

        const state = store.getState();

        const isAuthenticated = Boolean(state.currentUser);
        if (!isAuthenticated && isMembersOnly(category)) {
          return store.dispatch(
            navigateWithinForum(`/login?redirect=/${categorySlug}/${postSlug}`),
          );
        }

        if (!isServerSideRendering && !getIsDemoMode(state) && post._id) {
          const url = getUrl(state);
          const origin = getQueryParam(url, 'origin');
          store.dispatch(emitOpenPost(post._id));
          if (isRecentActivityCatsEnabled) {
            store.dispatch(markPostAsRead(post._id));
            store.dispatch(markPostViewed(post._id));
          } else {
            store.dispatch(incrementPostViewCount(post._id));
          }
          store.dispatch(
            pageOpened({
              page: POST_PAGE,
              post: {
                ...post,
                isNew: post.isNew || getIsPostNew(state)(post._id),
              },
              origin,
            }),
          );
        }

        fedopsLogger.interactionEnded(interactionName);
      })
      .catch(
        logErrorState(store, (error) => {
          if (error?.status === 401) {
            return redirect(ROUTE_LOGIN);
          } else {
            return handleFallbackRedirect(pathname, redirect, wixCodeApi);
          }
        }),
      );
  };

const getStyle = (config, state) => {
  const styleParams = { ...config.style.styleParams, ...getStyleParams(state) };
  return { ...config.style, styleParams };
};

const createHomeRouter = (store, config, wixCodeApi, compId) => (router, redirect) => {
  const state = store.getState();
  const style = getStyle(config, state);
  const feedType = getFeedType(state);

  if (isSite(state)) {
    wixCodeApi.seo.renderSEOTags();
  }

  const isMainPageEnabled = getIsMainPageEnabled(state, style);
  const isAuthenticated = Boolean(state.currentUser);

  if (!isAuthenticated && feedType === FeedType.MyPosts) {
    return store.dispatch(navigateWithinForum('/'));
  }

  if (!isSSR(state)) {
    store.dispatch(pageOpened({ page: CATEGORIES_PAGE, isMainPageEnabled }));
  }
  if ((isMainPageEnabled && !feedType) || feedType === FeedType.Categories) {
    return store.dispatch(fetchFooterPosts());
  }

  return createCategoryPageHandler(store, wixCodeApi, compId, config)(router, redirect);
};

const createCommentDeepLinkRouter =
  (store) =>
  ({ params }) => {
    const state = store.getState();

    const url = getUrl(state);
    const postId = getQueryParam(url, 'postId');
    const { commentId } = params;
    store
      .dispatch(resolveWixCommentsDeepLink(commentId, postId))
      .then((commentUrl) => {
        store.dispatch(navigateWithinForum(commentUrl));
      })
      .catch(() => {
        store.dispatch(navigateWithinForum('/'));
      });
  };

const createNotFoundPageHandler = (wixCodeApi) => () => {
  wixCodeApi.seo.setSeoStatusCode(404);
};

const matchCustomPath = async (wixCodeApi, path) => {
  const { key, segments } =
    (wixCodeApi.site &&
      wixCodeApi.site.getCustomizedUrlSegments &&
      (await wixCodeApi.site.getCustomizedUrlSegments(path))) ||
    {};
  switch (key) {
    case UrlMappingsKeys.FORUM_POST:
      return `/${segments.categorySlug}/${segments.postSlug}`;
    case UrlMappingsKeys.FORUM_POST_EDIT:
      return `/${segments.categorySlug}/${segments.postSlug}/edit`;
    case UrlMappingsKeys.FORUM_POST_DEEP_LINK_DATA:
      return `/${segments.categorySlug}/${segments.postSlug}/dl-${segments.deepLinkData}`;
    case UrlMappingsKeys.FORUM_POST_PAGINATION:
      return `/${segments.categorySlug}/${segments.postSlug}/p-${segments.page}`;
    case UrlMappingsKeys.FORUM_POST_PAGINATION_AND_DEEP_LINK_DATA:
      return `/${segments.categorySlug}/${segments.postSlug}/p-${segments.page}/dl-${segments.deepLinkData}`;
    case UrlMappingsKeys.FORUM_CATEGORY:
      return `/${segments.slug}`;
    case UrlMappingsKeys.FORUM_CATEGORY_CREATE_POST:
      return `/${segments.slug}/create-post`;
    case UrlMappingsKeys.FORUM_CATEGORY_CREATE_QUESTION:
      return `/${segments.slug}/create-question`;
    case UrlMappingsKeys.FORUM_CATEGORY_PAGINATION:
      return `/${segments.slug}/p-${segments.page}`;
    default:
      return '';
  }
};

export const createRouter = (
  store,
  config,
  wixCodeApi,
  compId,
  wixCommentsApi,
  handlePostChange,
  fedopsLogger,
) => {
  const router = new Router();
  const customRouteHandler = (pathname) => matchCustomPath(wixCodeApi, pathname);
  router.add(ROUTE_404, createNotFoundPageHandler(wixCodeApi));
  if (process.env.NODE_ENV === 'development') {
    router.add(ROUTE_DEV_PLAYGROUND);
  }
  router.add(ROUTE_SEARCH, createSearchPageRouter(store, wixCodeApi));
  router.add(ROUTE_LOGIN);
  router.add(ROUTE_ACCOUNT_SUSPENDED);
  router.add(ROUTE_CREATE_POST, createCreatePostRouteHandler(store, DISCUSSION));
  router.add(ROUTE_CREATE_QUESTION, createCreatePostRouteHandler(store, QUESTION));
  router.add(ROUTE_CATEGORY_CREATE_POST, createCreatePostRouteHandler(store, DISCUSSION));
  router.add(ROUTE_CATEGORY_CREATE_QUESTION, createCreatePostRouteHandler(store, QUESTION));
  router.add(ROUTE_POST_EDIT, createPostEditRouteHandler(store));
  router.add(ROUTE_COMMENT_DEEP_LINK, createCommentDeepLinkRouter(store));
  router.add(ROUTE_HOME, createHomeRouter(store, config, wixCodeApi, compId));
  router.add(ROUTE_CATEGORY, createCategoryPageHandler(store, wixCodeApi, compId, config));
  router.add(
    ROUTE_POST,
    createPostPageRouter(store, wixCodeApi, wixCommentsApi, handlePostChange, fedopsLogger),
  );
  router.addCustomRouteHandler(customRouteHandler);
  router.fallback(ROUTE_404);
  return router;
};
